import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import { GatsbyImage } from "gatsby-plugin-image"
import { Footer } from "../components/footer"
import Layout from "../components/layout"
import Seo from "../components/seo"

const CaseTemplate = ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <Seo title={"Case: " + post.frontmatter.title}/>
      <div className="relative pt-6 pb-8">
        <Header />
      </div>
      <div className="relative pt-8 pb-16 bg-white overflow-hidden">
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            {post.frontmatter.image.relativeDirectory !== 'logo2' && <div className="flex flex-col overflow-hidden">
              <div className="flex-shrink-0 mx-auto mx-auto pb-8">
                {post.frontmatter.image.extension === 'svg' ?
                  <img
                    style={{height: '250px'}}
                    src={post.frontmatter.image.publicURL}
                    alt={post.frontmatter.title} />
                  :
                  <GatsbyImage
                    image={post.frontmatter.image.childImageSharp.gatsbyImageData}
                    alt={post.frontmatter.title} />
                }
              </div>
            </div>}
            <h1>
          <span
            className="block text-base text-center text-gray-900 font-semibold tracking-wide uppercase">{post.frontmatter.subTitle}</span>
              <span
                className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-black sm:text-4xl">{post.frontmatter.title}</span>
            </h1>
            {/*<p className="mt-8 text-xl text-gray-500 leading-8">{post.frontmatter.ingress}</p>*/}
          </div>
          <div className="mt-6 prose prose-indigo prose-lg text-gray-900 mx-auto" dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>
      </div>
      <div className="bg-gray-900 text-gray-200">
        <Footer />
      </div>
    </Layout>
  )
}

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                subTitle
                by
                ingress
                image {
                    extension
                    publicURL
                    childImageSharp {
                        gatsbyImageData(
                            height: 250
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                    relativeDirectory
                }
            }
        }
    }
`

export default CaseTemplate;